<template>
  <div class="article__wrapper">
    <div class="main">
      <p class="bread">
        <a :href="'/' + addHrefSearch()" class="home">首页></a
        ><a class="home" :href="'/articleList' + addHrefSearch()"> 技术文章 </a
        >><span class="course"> 文章详情 </span>
      </p>
      <div class="tabs-des">
        <div class="tab-left">
          <div class="content">
            <div class="tit">
              <h1 v-html="articleMessage.articleTitle"></h1>
              <p>
                <span style="margin-right: 50px"
                  >发布时间：<em v-html="articleMessage.createTime"></em>
                </span>
                <span class="keyword"
                  >关键词：<em
                    :title="articleMessage.keyWordName"
                    v-html="articleMessage.keyWordName"
                  ></em
                ></span>
                <span class="share">分享到：</span>
                <img
                  @click="shareWeibo"
                  class="weibo"
                  src="~@/assets/images/icons/weibo.png"
                  alt=""
                />
                <img
                  @click="shareQQ"
                  class="qq"
                  src="~@/assets/images/icons/qq.png"
                  alt=""
                />
              </p>
            </div>
            <div class="article">
              <pre>
                  <p v-html='articleMessage.content'></p>
                </pre>
            </div>
          </div>

          <div class="c_page_bottom">
            <div class="c_page_bottom_item">
              <div class="c_page_bottom_item_prev"></div>
              <p>上一篇</p>
              <a
                v-if="aid > 1"
                :href="'/article/' + (aid - 1) + addHrefSearch()"
                target="_blank"
                v-text="
                  allArticleList[aid - 2]
                    ? allArticleList[aid - 2].articleTitle
                    : ''
                "
              ></a>
            </div>
            <div class="c_page_bottom_item">
              <div class="c_page_bottom_item_next"></div>
              <p>下一篇</p>
              <a
                v-if="aid < allArticleList.length"
                :href="'/article/' + (aid + 1) + addHrefSearch()"
                target="_blank"
                v-text="
                  allArticleList[aid] ? allArticleList[aid].articleTitle : ''
                "
              ></a>
            </div>
          </div>

          <div class="rcmd-article-list">
            <h3>推荐阅读</h3>
            <ul>
              <li v-for="item in allArticleList.slice(0, 8)">
                <a
                  :href="'/article/' + item.id + addHrefSearch()"
                  target="_blank"
                  >· {{ item.articleTitle }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-right">
          <keep-alive>
            <aside-bar />
          </keep-alive>
        </div>
      </div>
    </div>

    <!--     <el-dialog
      :lock-scroll="false"
      custom-class="shareModal"
      :visible.sync="dialogVisible"
      width="500px"
      title="分享到微信朋友圈"
    >
      <div>
        <div id="qrcode"></div>
        <p class="top">打开微信，点击底部的“发现”，</p>
        <p>使用 “扫一扫” 即可将网页分享到我的朋友圈。</p>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import "assets/css/article.less";
import AsideBar from "views/articleList/AsideBar";
import { getArticle } from "network/article";
import { mapState } from "vuex";

export default {
  name: "Article",
  components: {
    AsideBar,
  },
  computed: {
    ...mapState({
      allArticleList: (store) => store.ar.allArticleList,
    }),
  },
  data() {
    return {
      aid: parseInt(this.$route.params.id),
      articleMessage: {},
      dialogVisible: false,
    };
  },
  methods: {
    async initArticle() {
      const res = await getArticle(this.aid);
      this.articleMessage = res.data;
      document.title = this.articleMessage.articleTitle; // 更改标题
    },
    async generateQrcode() {},

    /*     shareWechat() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.generateQrcode();
      });
    }, */
    shareWeibo() {
      window.open(
        `https://service.weibo.com/share/share.php?
        url=${window.location.href}&pic=https%3A%2F%2Fwww.gulixueyuan.com%2Ffiles%2Fcourse%2F2019%2F03-26%2F1842146820b8294259.jpg`
      );
    },
    shareQQ() {
      window.open(
        `https://connect.qq.com/widget/shareqq/index.html?
        url=${window.location.href}`
      );
    },
  },
  mounted() {},
  created() {
    if (!this.allArticleList.length) this.$store.dispatch("INIT_ARTICLES");
    this.initArticle();
  },
};
</script>

<style lang="less">
</style>