<template>
  <div class="aside__wrapper">
    <div v-if="adverImgSrc" class="adver">
      <a :href="adverLinkUrl" target="_blank">
        <img :src="adverImgSrc" v-if="adverImgSrc" alt="" />
      </a>
    </div>

    <div class="recommond-videos">
      <h1 class="title">热门视频推荐</h1>
      <a
        @click="navToVideoPage(list)"
        v-for="(list, index) in recommendList"
        :key="list.id"
        :class="{ borderBottom0: index === recommendList.length - 1 }"
        class="des"
        :href="`/video/${list.subjectId}/${list.id}` + addHrefSearch()"
        target="_blank"
      >
        <div class="container">
          <img :src="list.url" alt="" />
        </div>
        <div>
          <h1 v-html="list.courseName"></h1>
          <div class="detail">
            <div class="left">
              <p class="file">共<span v-html="list.outlineNum"></span>节课</p>
              <p class="name"><span v-html="list.studyNum"></span>人学习</p>
            </div>
            <div class="advanced" :class="'levelColor' + list.level">
              <span></span>
              <i v-html="formatLevel(list.level)"></i>
            </div>
          </div>
        </div>
      </a>
    </div>

    <hot-labels />
  </div>
</template>

<script>
import { getAdver, getRecomdVideos } from "network/common";
import HotLabels from "common/HotLabels";

export default {
  name: "AsideBar",
  components: {
    HotLabels,
  },
  props: {
    navToArticle: Function,
  },
  data() {
    return {
      // 广告位
      adverImgSrc: "",
      adverLinkUrl: "",
      // 推荐视频
      recommendList: [],
    };
  },
  methods: {
    async getAdver(ad) {
      const res = await getAdver(ad);
      if (res.data.length > 0) {
        this.adverImgSrc = res.data[0].url;
        this.adverLinkUrl = res.data[0].linkUrl;
      }
    },
    async getRecomdVideos() {
      const res = await getRecomdVideos();
      this.recommendList = res.data;
    },
    navToVideoPage(item) {
      return;
      this.$router.push({
        path: `/video/${item.subjectId}/${item.id}`,
        query: this.$route.query,
      });
    },
  },
  created() {
    this.getAdver(1);
    this.getRecomdVideos();
  },
};
</script>

<style scoped>
</style>